import React, { useCallback, useState } from "react";
import { Tooltip } from "../../../index";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faLeftFromBracket } from "@fortawesome/pro-light-svg-icons";

interface Props {
  handleLogout: () => Promise<void>;
}

const LogoutButton = ({ handleLogout }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await handleLogout();
    } catch (e) {
      setLoading(false);
    }
  }, []);

  return (
    <Tooltip title={t("menu_logout")}>
      <IconButton
        className={"LogoutButton"}
        aria-label="menu_logout"
        onClick={logout}
        sx={{
          position: "absolute",
          left: 16,
          top: 16,
          height: 50,
          width: 50,
        }}
        disabled={loading}
      >
        <FontAwesomeIcon
          icon={loading ? faSpinnerThird : faLeftFromBracket}
          spin={loading}
        />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
