import React, { useMemo, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { UploadPageSectionTitle } from "../UploadsManager/UploadPageSectionTitle";
import { DeliveryTypeGroup } from "./DeliveryTypeGroup";
import { LanguageInputs } from "./LanguageInputs";
import { AdvancedSettings } from "./AdvancedSettings/AdvancedSettings";
import {
  Button,
  UpdateClientUploadsSettings,
  UploadsPageFeatureFlags,
} from "../../../../index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";
import {
  AvailableUploadDeliveryTypes,
  Direction,
  LanguageData,
  Project,
  uploadAdvancedSettingsOptions,
  UploadFileForm,
  ValidationPreset,
} from "@sumit-platforms/types";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { ProjectsDropdown } from "../../ProjectsDropdown/ProjectsDropdown";
import { ValidationsInput } from "./AdvancedSettings/ValidationsInput";
import { AdvancedSettingsButton } from "./AdvancedSettings/AdvancedSettingsButton";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  projects: Project[];
  config: any;
  handleProjectCreate: (projectName: string) => Promise<Project | null>;
  idClient: number;
  validations: ValidationPreset[];
  defaultMultiTrackFileName: string;
  isAllMediasSameDuration: boolean;
  isConcatDisabled: boolean;
  isMultiTrackDisabled: boolean;
  maxGapInMinForMultiTrack: number;
  featureFlags?: UploadsPageFeatureFlags;
  languages: LanguageData[];
  dir?: Direction;
}

export const SetOrder = ({
  uploadState,
  languages,
  onUploadsUpdate,
  config,
  projects,
  handleProjectCreate,
  idClient,
  validations,
  defaultMultiTrackFileName,
  isAllMediasSameDuration,
  maxGapInMinForMultiTrack,
  isMultiTrackDisabled,
  isConcatDisabled,
  featureFlags,
  dir,
}: Props) => {
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const { t } = useTranslation();
  const upperSectionRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const renderOptions = useMemo(() => {
    if (!uploadState?.jobType?.typeName) return;
    const currentType = uploadState.jobType
      .typeName as AvailableUploadDeliveryTypes;
    return uploadAdvancedSettingsOptions[currentType];
  }, [uploadState]);

  return (
    <Box className={"SetOrderWrapper"} ref={containerRef}>
      <Box className={"upperSectionSetOrderSettings"} ref={upperSectionRef}>
        <UploadPageSectionTitle title={`2. ${t("set_order")}`} />
        <DeliveryTypeGroup
          deliveryType={uploadState.jobType?.typeName}
          onUploadsUpdate={onUploadsUpdate}
          dir={dir}
        />
        <LanguageInputs
          languages={languages}
          inputLanguage={uploadState.inputLanguage || []}
          outputLanguage={uploadState.outputLanguage || []}
          onUploadsUpdate={onUploadsUpdate}
          dir={dir}
        />

        <Box className={"ProjectAndValidationsWrapper"}>
          <ProjectsDropdown
            projects={projects}
            onUploadsUpdate={onUploadsUpdate}
            uploadState={uploadState}
            handleProjectCreate={handleProjectCreate}
            dir={dir}
          />

          {renderOptions?.showValidation && (
            <ValidationsInput
              dir={dir}
              onUploadsUpdate={onUploadsUpdate}
              uploadState={uploadState}
              validations={validations}
            />
          )}
        </Box>

        <AdvancedSettingsButton
          disabled={!uploadState?.jobType?.typeName}
          isOpen={isAdvancedOpen}
          onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          dir={dir}
        />
      </Box>
      {isAdvancedOpen && (
        <AdvancedSettings
          height={
            upperSectionRef.current && containerRef.current
              ? `calc(${containerRef.current.clientHeight}px - ${upperSectionRef.current.clientHeight}px)`
              : "55%"
          }
          uploadState={uploadState}
          onUploadsUpdate={onUploadsUpdate}
          config={config}
          projects={projects}
          handleProjectCreate={handleProjectCreate}
          idClient={idClient}
          validations={validations}
          defaultMultiTrackFileName={defaultMultiTrackFileName}
          isAllMediasSameDuration={isAllMediasSameDuration}
          maxGapInMinForMultiTrack={maxGapInMinForMultiTrack}
          isMultiTrackDisabled={isMultiTrackDisabled}
          isConcatDisabled={isConcatDisabled}
          featureFlags={featureFlags}
          dir={dir}
          renderOptions={renderOptions}
        />
      )}
    </Box>
  );
};
