import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "./SignUpForm.scss";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Button, FormInput, GoogleSignInButton } from "../../../../index";
import { AlreadyHaveAnAccount } from "./AlreadyHaveAnAccount";
import { useUserInvitation } from "../../../../hooks";
import { AuthService } from "../../../../services";

const MIN_PASS_LENGTH = 8;

type FormValues = {
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmPassword?: string;
  phoneNumber?: string;
};

export const SignUpForm = ({
  onSignupSuccess,
  config,
}: {
  onSignupSuccess: () => void;
  config: any;
}) => {
  const authService = useMemo(() => AuthService({ config }), []);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const {
    invitationDetails,
    loading: invitationLoading,
    token,
  } = useUserInvitation({
    config,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onChange",
    shouldUnregister: false,
    defaultValues: {
      email: invitationDetails?.email || "",
    },
  });

  const onSubmit = async (data: any) => {
    const userDetails = {
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      phone: data.phoneNumber,
      email: data.email,
    };
    try {
      setError("");
      setLoading(true);
      const authRes = await authService.signUp(userDetails, token || "");

      if (authRes) {
        onSignupSuccess();
      }
    } catch (e: any) {
      console.error(e);
      setError(e?.message || "");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (invitationDetails?.email) {
      setValue("email", invitationDetails.email);
    }
  }, [invitationDetails, setValue]);

  const validatePhoneNumber = (value: string) => {
    const phoneNumber = parsePhoneNumberFromString(value, "US");
    return phoneNumber && phoneNumber.isValid()
      ? true
      : t("sign_up_phone_number_invalid");
  };

  const validateEmail = (email: string, errorText: string): true | string => {
    if (!email) return true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) ? true : errorText;
  };

  const validateEnglishCharacters = (value?: string): true | string => {
    if (!value) return true;
    const regex = /^[A-Za-z\s]*$/;
    return regex.test(value) ? true : t("sign_up_only_english_characters");
  };

  return (
    <Box width={"500px"} className="BazarSignUpForm">
      <GoogleSignInButton
        signIn={console.log}
        signOut={console.log}
        disabled={true}
      />
      <Box className={"orDivider"}>
        <Box className={"separator"}></Box>
        <Typography variant={"subtitle2"}>{t("or")?.toLowerCase()}</Typography>
        <Box className={"separator"}></Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={"fullNameWrapper"}>
          <FormInput
            label={t("first_name")}
            error={errors["firstName"]?.message as string}
            register={{
              ...register("firstName", {
                required: t("sign_up_first_name_required"),
                validate: validateEnglishCharacters,
              }),
            }}
          />
          <FormInput
            label={t("last_name")}
            error={errors["lastName"]?.message as string}
            register={{
              ...register("lastName", {
                validate: validateEnglishCharacters,
              }),
            }}
          />
        </Box>
        <FormInput
          label={t("email")}
          type="email"
          register={{
            ...register("email", {
              required: t("sign_up_email_required"),
              validate: (value?: string) =>
                validateEmail(value || "", t("sign_up_email_invalid")),
            }),
          }}
          error={errors["email"]?.message as string}
        />

        <FormInput
          label={t("phone")}
          type={"phone"}
          control={control}
          requiredText={t("sign_up_phone_number_required")}
          validate={validatePhoneNumber}
          error={errors["phoneNumber"]?.message as string}
        />

        <FormInput
          label={t("password")}
          type="password"
          error={errors["password"]?.message as string}
          register={{
            ...register("password", {
              required: t("sign_up_pass_required"),
              validate: (value) => {
                if (!value) return;
                const minLength = value.length >= MIN_PASS_LENGTH;
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasNumber = /[0-9]/.test(value);
                // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

                if (!minLength) return t("sign_up_pass_too_short");
                if (!hasUpperCase) return t("sign_up_pass_no_uppercase");
                if (!hasLowerCase) return t("sign_up_pass_no_lowercase");
                if (!hasNumber) return t("sign_up_pass_no_number");
                // if (!hasSpecialChar) return t("sign_up_pass_no_special");

                return true;
              },
            }),
          }}
        />
        <FormInput
          label={t("confirm_password")}
          type="password"
          register={{
            ...register("confirmPassword", {
              required: t("sign_up_confirm_pass_required"),
              validate: (value) =>
                value === watch("password") || t("sign_up_pass_doesnt_match"),
            }),
          }}
          error={errors["confirmPassword"]?.message as string}
        />
        <Button
          type="submit"
          className={"SignUpFormSubmitButton"}
          loading={loading}
        >
          {t("continue")}
        </Button>
        <AlreadyHaveAnAccount />
        <Typography color={"error"} mt={0.5} textAlign={"center"}>
          {error}
        </Typography>
      </form>
    </Box>
  );
};
