import { Typography, TypographyProps } from "@mui/material";
import classNames from "classnames";
import { Direction } from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/pro-light-svg-icons";

import "./AdvancedSettingsButton.scss";

interface AdvancedSettingsButtonProps {
  disabled?: boolean;
  isOpen: boolean;
  dir?: Direction;
  className?: string;
  onClick?: () => void;
}

export const AdvancedSettingsButton = ({
  className,
  disabled,
  isOpen,
  onClick,
  dir,
  ...props
}: AdvancedSettingsButtonProps & TypographyProps) => {
  const { t } = useTranslation();
  return (
    <Typography
      variant={"subtitle1"}
      className={classNames("AdvancedSettingsButton", className, {
        disabled,
      })}
      onClick={onClick}
      {...props}
    >
      <>
        {t("advanced_settings")}
        <FontAwesomeIcon
          icon={
            isOpen
              ? faChevronDown
              : dir === "ltr"
              ? faChevronRight
              : faChevronLeft
          }
          style={{ padding: "0 8px", width: "24px" }}
        />
      </>
    </Typography>
  );
};
