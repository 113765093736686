import React, { useCallback, useEffect, useState } from "react";
import { useMFA } from "../../../hooks";
import { MFAMethodOptions } from "@sumit-platforms/types";
import { VerifyOTPCode, VerifySMSCode } from "../../../index";

interface MfaVerifyProps {
  config: any;
  type: MFAMethodOptions;
  onSuccess: () => Promise<void>;
  onFail: () => void;
  logOut?: () => Promise<void>;
  showHeader?: boolean;
  showHeaderImage?: boolean;
}

export const MfaVerify = ({
  config,
  type,
  onFail,
  onSuccess,
  logOut,
  showHeader,
  showHeaderImage,
}: MfaVerifyProps) => {
  const { verifySMSCode, validateMfaSession, verifyOTPCode, resendSMS } =
    useMFA({
      config,
      onVerificationSuccess: async () => {
        await onSuccess();
      },
      onVerificationFailure: () => {
        onFail();
      },
    });

  return (
    <div className={"mfaVerify"}>
      {type === "sms" ? (
        <VerifySMSCode
          verifySMSCode={verifySMSCode}
          resendCode={resendSMS}
          validateMfaSession={validateMfaSession}
          showHeader={showHeader}
          showHeaderImage={showHeaderImage}
          logOut={logOut}
        />
      ) : type === "totp" ? (
        <VerifyOTPCode
          verifyOTPCode={verifyOTPCode}
          validateMfaSession={validateMfaSession}
          showHeader={false}
        />
      ) : null}
    </div>
  );
};
