import axios from "axios";
import { User } from "@sumit-platforms/types";

const AuthService = ({ config }: { config: any }) => {
  const endpoint = `${config.server.host}/${config.server.users}`;

  const resetPassword = async (email: string) => {
    await axios.post(`${endpoint}/resetPassword`, { email });
  };

  const signUp = async (newUserDetails: any, invitationToken: string) => {
    try {
      const newUser: User = (
        await axios.post<User>(endpoint, {
          userDetails: newUserDetails,
          invitationToken,
        })
      ).data;
      return newUser;
    } catch (err: any) {
      throw err.response.data;
    }
  };

  const getUser: () => Promise<User> = async () => {
    try {
      const userData = await axios.get<User>(`${endpoint}/getLoggedInUser`, {
        withCredentials: true,
      });
      if (userData.status !== 200) {
        throw new Error("Failed to get user detail");
      }
      return userData.data;
    } catch (err: any) {
      throw new Error(err);
    }
  };

  return {
    signUp,
    getUser,
    resetPassword,
  };
};

export default AuthService;
