import React, { FC } from "react";
import { ResetPasswordForm } from "@sumit-platforms/ui-bazar";
import config from "../../config";

import "./Auth.scss";

const ResetPasswordPage: FC = (): JSX.Element => {
  return (
    <div className="ResetPasswordPage">
      <ResetPasswordForm config={config} />
    </div>
  );
};

export default ResetPasswordPage;
