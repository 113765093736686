import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { SignInWithEmailAndPasswordForm } from "./SignInWithEmailAndPassword";
import { AuthenticateWithEmailAndPasswordResponse } from "@sumit-platforms/types";
import { MfaVerify } from "./MfaVerifyForm";

import "./SignInForm.scss";

interface SignInFormProps {
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<AuthenticateWithEmailAndPasswordResponse>;
  resetPassword: (
    email: string
  ) => Promise<{ success: boolean; message?: string }>;
  hint: { email?: { value?: string; disabled?: boolean } };
  config: any;
  onSuccess: () => void;
  onFail: () => void;
  onResetPasswordSuccess: () => void;
}

export const SignInForm = ({
  signInWithEmailAndPassword,
  resetPassword,
  hint,
  config,
  onSuccess,
  onFail,
  onResetPasswordSuccess,
}: SignInFormProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | JSX.Element>("");
  const [signedInStageSuccess, setSignedInStageSuccess] = useState(false);
  const [mfaType, setMfaType] = useState<
    AuthenticateWithEmailAndPasswordResponse["mfaType"] | null
  >(null);
  const [error, setError] = useState<string>("");

  const handleEmailLogin = async (data: {
    email: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      setError("");
      setMessage("");
      const result = await signInWithEmailAndPassword(
        data.email,
        data.password
      );

      if (result.success && result.mfaType) {
        setSignedInStageSuccess(true);
        setMfaType(result.mfaType);
      }

      if (!result.success) {
        setError(result.message || t("login_failed"));
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSuccess = useCallback(async () => {
    onSuccess();
  }, []);

  const handleMfaFail = useCallback(() => {
    onFail();
  }, []);

  const handleResetPassword = useCallback(async () => {
    try {
      if (!hint?.email?.value) return;
      setMessage("");
      setError("");
      await resetPassword(hint?.email?.value || "");
      setMessage(
        <Trans
          i18nKey={"forgot_password_email_sent"}
          components={{ br: <br /> }}
          values={{ email: hint?.email?.value }}
        />
      );
      onResetPasswordSuccess();
    } catch (e: any) {
      setError(e?.message);
    }
  }, []);

  return (
    <div className={"SignInForm"}>
      <div className={"signInFormContent"}>
        {signedInStageSuccess && mfaType ? (
          <MfaVerify
            config={config}
            type={mfaType}
            onSuccess={handleMfaSuccess}
            onFail={handleMfaFail}
          />
        ) : (
          <SignInWithEmailAndPasswordForm
            onSubmit={handleEmailLogin}
            hint={hint}
            loading={loading}
            resetPassword={handleResetPassword}
          />
        )}
        <p className={"message"}>{message || ""}</p>
        <p className={"error"}>{error || ""}</p>
      </div>
    </div>
  );
};
