import React from "react";
import LogoutButton from "./LogoutButton";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";

interface Props {
  handleLogout?: () => Promise<void>;
  showHeaderImage?: boolean;
}

export const MFAHeader = ({ handleLogout, showHeaderImage = true }: Props) => {
  return (
    <div className="headerTitle">
      {handleLogout && <LogoutButton handleLogout={handleLogout} />}
      {showHeaderImage && (
        <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
      )}
    </div>
  );
};
