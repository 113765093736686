import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export const AlreadyHaveAnAccount = () => {
  const { t } = useTranslation();
  return (
    <Box
      className={"alreadyHaveAccount"}
      display="flex"
      justifyContent="space-around"
    >
      <Typography>
        {t("already_have_an_account")}
        <Typography
          component="a"
          mx={1}
          className={"loginLink"}
          href={"/login"}
        >
          {t("login")}
        </Typography>
      </Typography>
    </Box>
  );
};
