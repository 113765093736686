import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { IconButton } from "../IconButton/IconButton";

interface Props {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PasswordReveal = ({ setShowPassword, showPassword }: Props) => {
  return (
    <IconButton
      className="PasswordRevealIcon eyeIcon"
      onClick={() => setShowPassword((prev) => !prev)}
      style={{
        padding: 14,
        fontSize: 18,
        width: 20,
        height: 20,
        position: "absolute",
        top: 4,
        right: 4,
      }}
      icon={showPassword ? faEye : faEyeSlash}
    />
  );
};
