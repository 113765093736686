import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AdvancedSettingsButton,
  bazarTheme,
  Button,
  Modal,
} from "../../../../../../index";
import { Box, ThemeProvider } from "@mui/material";
import { SaveRecordInput } from "./SaveRecordHeader";
import {
  Client,
  Direction,
  LanguageData,
  Project,
  ProjectPage,
} from "@sumit-platforms/types";
import { RecordAdvancedSettings } from "./RecordAdvancedSettings";
import ProjectService from "../../../../../services/projectService";
import { StoreApi, UseBoundStore } from "zustand";
import { SaveRecordSubmission } from "./SaveRecordSubmission";
import { DeliveryTypeGroup } from "../../../../NewUploads/SetOrder/DeliveryTypeGroup";
import { recordStore } from "../../../../../../store";
import { LanguageInputs } from "../../../../NewUploads/SetOrder/LanguageInputs";
import { AutoTypeGroup } from "../../../../NewUploads/Submission/AutoDeliveryGroup";
import { useTranslation } from "react-i18next";

import "./SaveRecordModal.scss";

interface Props {
  handleSubmitUpload: () => any;
  languages: LanguageData[];
  clientStore: UseBoundStore<
    StoreApi<{
      client: Client | null;
      setClient: (client: Client | null) => void;
    }>
  >;
  config: any;
  dir: Direction;
  closeModal: () => void;
}

export const SaveRecordModal = ({
  handleSubmitUpload,
  languages,
  clientStore,
  config,
  dir,
  closeModal,
}: Props) => {
  const { uploadSettings, updateUploadSettings } = recordStore();
  const { t } = useTranslation();
  const [isAdvSettingsOpen, setIsAdvSettingsOpen] = useState(false);
  const { client } = clientStore();
  const [projects, setProjects] = useState<ProjectPage[]>([]);
  const projectService = useMemo(() => ProjectService({ config }), [config]);

  const handleProjectCreate = useCallback(
    async (projectName: string): Promise<Project | null> => {
      try {
        if (!client?.idClient || !projectService) return null;
        const project = await projectService.createNewProject({
          idClient: client.idClient,
          name: projectName,
        });

        setProjects((prev) => [{ ...project, settings: {} }, ...prev]);
        return project;
      } catch (err) {
        return null;
      }
    },
    [client?.idClient]
  );

  const getProjects = useCallback(async () => {
    if (!client?.idClient || !projectService) return;
    const projects = await projectService?.getClientProjects(
      client.idClient,
      true
    );
    setProjects(projects as ProjectPage[]);
  }, [client?.idClient, projectService]);

  useEffect(() => {
    getProjects();
  }, [client?.idClient]);

  return (
    <Modal type={"info"} closeModal={closeModal}>
      <ThemeProvider theme={bazarTheme}>
        <Box
          className={"SaveRecordModal"}
          p={3}
          overflow={"scroll"}
          height={"100%"}
          maxHeight={"100%"}
        >
          <Box
            className={"saveRecordModalForm"}
            height={"92%"}
            overflow={"scroll"}
          >
            <SaveRecordInput dir={dir} />
            <DeliveryTypeGroup
              dir={dir}
              onUploadsUpdate={updateUploadSettings}
              deliveryType={uploadSettings.jobType?.typeName}
            />
            <LanguageInputs
              inputLanguage={uploadSettings.inputLanguage || []}
              outputLanguage={uploadSettings.outputLanguage || []}
              onUploadsUpdate={updateUploadSettings}
              languages={languages}
              dir={dir}
            />
            <AutoTypeGroup
              dir={dir}
              uploadState={uploadSettings}
              onUploadsUpdate={updateUploadSettings}
              label={`${t("service")}:`}
            />
            <AdvancedSettingsButton
              isOpen={isAdvSettingsOpen}
              onClick={() => setIsAdvSettingsOpen(!isAdvSettingsOpen)}
              dir={dir}
              disabled={false}
              py={2}
            />
            {isAdvSettingsOpen && (
              <RecordAdvancedSettings
                dir={dir}
                projects={projects}
                handleProjectCreate={handleProjectCreate}
                config={config}
                idClient={client?.idClient}
              />
            )}
          </Box>
          <SaveRecordSubmission
            handleSubmit={handleSubmitUpload}
            handleCancel={closeModal}
          />
        </Box>
      </ThemeProvider>
    </Modal>
  );
};
