import config from "../../config";
import React, { useEffect, useMemo, useRef } from "react";
import _ from "lodash";
import { addDays, format } from "date-fns";
import {
  ConfirmModal,
  CustomModalButton,
  ExportJobModal,
  JobRestoreModal,
  SpinningLoader,
  SummarizeJobModal,
} from "@sumit-platforms/ui-bazar";

import {
  useAuth,
  useFeatureFlag,
  useJob,
  useNavigateOutPrompt,
  useToast,
} from "@sumit-platforms/ui-bazar/hooks";
import { useAlert, useModal } from "@sumit-platforms/ui-bazar/store";

import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Grid } from "@mui/material";
import {
  faFileExport,
  faHistory,
  faRectangleList,
  faTruckFast,
} from "@fortawesome/pro-light-svg-icons";
import Editor, {
  ContentEditableEditor,
  SlateEditorImperativeRef,
} from "@sumit-platforms/editor";

import {
  exportService as ExportService,
  JobService,
  MediaService,
  UserService,
} from "@sumit-platforms/ui-bazar/services";

import {
  BriefExportOptions,
  ExportOptions,
  JobAutoTranscriptDeliveryOptions,
  JobRevision,
  jobTypesOptions,
  JobWithData,
  MenuBar,
  Platform,
  SaveTriggers,
  User,
  UserSettings,
} from "@sumit-platforms/types";
import {
  getJobTypesOption,
  getTcOffsetByStartTime,
  isJobReadyForSummarizeClients,
  isJobReadyForSummarizeOperation,
} from "@sumit-platforms/ui-bazar/utils";

import { useGlobalData, useNavigationBlocker } from "../../store";
import { useShallow } from "zustand/react/shallow";

import "./Job.scss";

const jobService = JobService({ config });
const userService = UserService({ config });
const mediaService = MediaService({ config });
const exportService = ExportService({
  config,
});

const Job = () => {
  //GLOBAL STATE
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const { jobTypes, setToast, direction } = useGlobalData(
    useShallow((state) => ({
      jobTypes: state.jobTypes,
      setToast: state.setToast,
      direction: state.direction,
    }))
  );
  const { toastInfo, toastSuccess, toastError } = useToast({ setToast });
  const { setBlockNavModal, blockNavModal, clearBlockNavModal } =
    useNavigationBlocker();
  const { user, setUser } = useAuth({ config });

  const unmountNavigationBlocker = useNavigateOutPrompt({
    useModal,
    onBeforeConfirm: async () => {
      // await onBeforeNavigateOutConfirm();
    },
  });
  const navigate = useNavigate();
  const { idJob = null } = useParams();
  const { job, setJob, jobSettings, getJobRevisions } = useJob({
    config,
    idJob: Number(idJob),
  });
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const experimentalSubtitlesEditor = useFeatureFlag(
    "must_experimentalSubtitlesEditor"
  );
  const findAndReplace = useFeatureFlag("must_findAndReplace");
  const timecodePickerFF = useFeatureFlag("timecodePicker");
  const useNewKeyboardShortcuts = useFeatureFlag("must_newKeyboardShortcuts");

  const karaokeFF = useFeatureFlag("must_karaoke");
  const virtualizeSpeakersFF = useFeatureFlag("must_virtualizedSpeakers");
  const allowDownloadMediaFF = useFeatureFlag("must_allow_download_media");
  const jobAiChatFF = useFeatureFlag("must_job_ai_chat");

  const editorRef = useRef<SlateEditorImperativeRef>(null);
  const mode = useMemo(
    () => getJobTypesOption(job, Platform.MUST)?.editorMode,
    [job]
  );

  const contentEditableFF =
    useFeatureFlag("must_contentEditableEditor") ||
    (["protocol", "interview", "brief"].includes(job?.type?.typeName || "") &&
      mode !== "subtitles-translation");
  //TODO: Fix that use effect. navigation is broken
  // useEffect(() => {
  //   if (editorRef?.current?.saveJob) {
  //     if (!blockNavModal) {
  //       setBlockNavModal(handleNavigateOut);
  //     }
  //   }
  // }, [editorRef, handleNavigateOut]);

  useEffect(() => {
    return () => {
      clearBlockNavModal();
    };
  }, [clearBlockNavModal]);

  const saveJob = async (job: JobWithData, saveMethod: SaveTriggers) => {
    if (!idJob) return;
    try {
      toastInfo(t("saving"));
      await jobService.saveJobData({
        idJob: Number(idJob),
        jobData: job.data,
        isClientRevision: true,
        tcOffsets: job.tcOffsets,
        saveTrigger: saveMethod,
      });
      toastSuccess(t("save_success"));
    } catch (err) {
      toastError(t("save_fail"), err);
    }
  };

  const orderFullTranscript = async () => {
    if (!idJob) return;
    toastInfo(t("order_transcription_info"));
    await jobService.orderFullTranscript(Number(idJob));
    toastSuccess(t("order_transcription_success"));
  };

  const updateUserSettings = async (newUserSettings: Partial<UserSettings>) => {
    try {
      const updatedSettings = await userService.updateUserSettings(
        newUserSettings
      );
      setUser({ ...(user as User), settings: updatedSettings });
      toastSuccess(t("update_user_settings_success"));
    } catch (e) {
      console.log(
        "Failed to update user settings. User settings will not be updated."
      );
      toastError(t("update_user_settings_failed"));
    }
  };

  const handleOrderFullTranscript = async () => {
    try {
      if (!job) return;
      openConfirmModal({
        title: t("order_transcription_title"),
        message: t("order_transcription_msg", {
          jobType: job.type.typeName,
          deliveryDate: format(
            addDays(new Date(), config.delivery.minDeliveryDays),
            "dd/MM/yyyy"
          ),
        }),
        confirm: async () => {
          await orderFullTranscript();
          navigate("/");
        },
        closeAfterConfirm: true,
      });
    } catch (err) {
      toastError(t("order_full_transcription_fail"));
    }
  };

  const downloadMedia = async (idMedia: number) => {
    const url = await mediaService.downloadMediaById(idMedia);
    if (url && typeof url === "string") {
      window.open(url, "_blank");
    } else {
      toastError(t("download_media_error"));
    }
  };

  const closeModal = (): void => {
    setModalType("info");
    clearModalContent();
  };

  const openConfirmModal = ({
    title,
    message,
    confirm,
    closeAfterConfirm = true,
    customButtons,
  }: {
    title: string;
    message: string;
    confirm: () => void;
    closeAfterConfirm: boolean;
    customButtons?: CustomModalButton[];
  }): void => {
    setModalType("info");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={closeModal}
        closeAfterConfirm={closeAfterConfirm}
        customButtons={customButtons || []}
      />
    );
  };

  function handleNavigateOut({ to }: { to: string }) {
    if (editorRef?.current?.isChangesSaved) {
      return unmountNavigationBlocker({ to });
    } else {
      navigate(to);
    }
  }

  async function onBeforeNavigateOutConfirm() {
    // currently not in use, cause problems
    // if (editorRef.current?.saveJob) {
    //   await editorRef.current?.saveJob(
    //     SaveTriggers.JOB_LEAVE_CONFIRMATION_MODAL
    //   );
    // }
  }

  const handleExportJob = async (
    exportArgs: ExportOptions & {
      jobIds: number[];
      fileName: string;
    }
  ) => {
    try {
      await exportService.exportJob(exportArgs);
    } catch (err) {
      toastError(t("export_failed"));
    } finally {
      closeModal();
    }
  };

  const handleOpenExportModal = () => {
    if (!job) return;

    setModalContent(
      <ExportJobModal
        jobTypes={jobTypes}
        jobType={job.type.typeName}
        jobName={job.name}
        templates={job.client?.templates}
        confirm={async (fileName, options) =>
          await handleExportJob({
            jobIds: [job.idJob],
            fileName,
            format: options.format,
            selectedRanges: options.selectedRanges,
            showSpeakers: options.showSpeakers,
            interval: options.interval,
            flip: options.flip,
            tc: options.tc,
            idDocxTemplate: options.idDocxTemplate,
            encoding: options.encoding,
            docxDefaultSettings: options.docxDefaultSettings,
            zeroSubtitle: options.zeroSubtitle,
            emptySubtitle: options.emptySubtitle,
            autoBreak: options.autoBreak,
            exportSourceJob: options.exportSourceJob,
          })
        }
        selectedJobs={[job]}
        selectedRanges={editorRef.current?.selectedRanges}
        tcOffset={getTcOffsetByStartTime(job.tcOffsets)}
        cancel={closeModal}
        disabled={false}
      />
    );
  };

  const handleSummarizeJob = async (
    idJob: number,
    options: BriefExportOptions
  ) => {
    try {
      await exportService.createJobSummary({ idJob, options });
      toastSuccess(t("export_summary_success"));
    } catch (err) {
      toastError(t("export_summary_failed"));
    } finally {
      closeModal();
    }
  };

  const jobRestoreApprove = async (revision: JobRevision) => {
    toastInfo(t("restoring_job"));
    try {
      const result = await jobService.restoreJob({
        idJobRevision: revision.idJobRevision,
      });

      const newJob = {
        ...job,
        data: result.dataJson,
      } as JobWithData;
      setJob(newJob);

      closeModal();
      toastSuccess(t("job_restored"));
    } catch (e) {
      toastError(t("job_restore_failed"));
    }
  };

  const onRestoreJobClick = (job: JobWithData) => {
    setModalContent(
      <JobRestoreModal
        idJob={job.idJob}
        onApprove={jobRestoreApprove}
        onCancel={closeModal}
        getJobRevisions={getJobRevisions}
        filterBy={{
          trigger: [
            SaveTriggers.JOB_DONE,
            SaveTriggers.OOONA_FINISH_REVIEW,
            SaveTriggers.JOB_AUTO_DELIVERED,
          ],
          isClientRevision: true,
        }}
      />
    );
  };

  const openSummarizeJobModal = (idJob: number, jobName: string) => {
    setModalContent(
      <SummarizeJobModal
        idJob={idJob}
        jobName={jobName}
        onApprove={handleSummarizeJob}
        onCancel={closeModal}
      />
    );
  };

  const menuItems = useMemo(() => {
    if (!job?.idJob) return;
    const _jobActions: MenuBar = {
      categories: [
        {
          name: t("file"),
          items: [
            {
              id: "restoreJob",
              name: t("restore_job"),
              icon: faHistory,
              action: onRestoreJobClick,
            },
            {
              id: "export",
              name: t("export_button"),
              icon: faFileExport,
              action: handleOpenExportModal,
            },
            {
              id: "brief",
              name: t("export_summary"),
              action: () => openSummarizeJobModal(job.idJob, job?.name || ""),
              icon: faRectangleList,
              hide: !isJobReadyForSummarizeOperation(job),
            },
            {
              id: "order",
              name: t("order_transcription_button"),
              icon: faTruckFast,
              action: handleOrderFullTranscript,
              hide:
                job.autoTranscriptDelivery !==
                JobAutoTranscriptDeliveryOptions.delivered,
            },
          ],
        },
      ],
      featuredButtons: [
        {
          id: "export",
          name: t("export_button"),
          icon: faFileExport,
          action: handleOpenExportModal,
          fullWidth: true,
        },
      ],
    };

    return _jobActions;
  }, [
    handleOpenExportModal,
    handleOrderFullTranscript,
    job,
    onRestoreJobClick,
    openSummarizeJobModal,
    t,
  ]);

  return !job || !job.data || !job.media ? (
    <Grid
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Grid item width={60} height={60}>
        <SpinningLoader disableShrink={true} />
      </Grid>
    </Grid>
  ) : (
    <div className="Job">
      {contentEditableFF && (mode === "transcript" || mode === "subtitles") ? (
        <ContentEditableEditor
          job={job}
          jobSettings={jobSettings}
          setJob={(job: JobWithData) => setJob(job)}
          save={saveJob}
          showSubtitles={jobTypesOptions[
            job.type.typeName || "subtitles"
          ].editorMode.includes("subtitles")}
          mode={mode}
          useModal={useModal}
          jobMenuItems={menuItems}
          platformDirection={direction}
          // jobActions={[
          //   {
          //     key: "brief",
          //     label: t("export_summary"),
          //     icon: faRectangleList,
          //     onClick: () => openSummarizeJobModal(job.idJob, job.name),
          //     fullButton: true,
          //     hide: !isJobReadyForSummarizeClients(job),
          //   },
          //   {
          //     key: "export",
          //     label: t("export"),
          //     icon: faFileExport,
          //     fullButton:
          //       job.autoTranscriptDelivery ===
          //       JobAutoTranscriptDeliveryOptions.delivered,
          //     onClick: handleOpenExportModal,
          //   },
          //   {
          //     key: "order",
          //     label: t("order_transcription_button"),
          //     icon: faTruckFast,
          //     onClick: handleOrderFullTranscript,
          //     fullButton: true,
          //     hide:
          //       job.autoTranscriptDelivery !==
          //       JobAutoTranscriptDeliveryOptions.delivered,
          //   },
          //   {
          //     key: "restoreJob",
          //     label: t("restore_job"),
          //     icon: faHistory,
          //     onClick: onRestoreJobClick,
          //   },
          // ]}
          ref={editorRef}
          featureFlags={{
            experimentalSubtitlesEditor,
            timecodePicker: timecodePickerFF,
            allowDownloadMedia: allowDownloadMediaFF,
            useNewKeyboardShortcuts,
            contentEditable: contentEditableFF,
            findAndReplace,
            karaoke: karaokeFF,
            virtualizeSpeakers: virtualizeSpeakersFF,
            rapidTimeUpdate: true,
            jobAiChatFF,
          }}
          downloadMedia={downloadMedia}
          userSettings={user?.settings}
          updateUserSettings={updateUserSettings}
          aiJobChatServerUrl={config.server.host + "/" + config.server.aiChat}
          aiChatOpenOnInit={true}
        />
      ) : (
        <Grid container className="Job Page" px={2}>
          <Grid container justifyContent={"center"} py={2}>
            <Grid item xs={12} className="editorSection">
              <Editor
                job={job}
                jobSettings={jobSettings}
                setJob={(job: JobWithData) => setJob(job)}
                save={saveJob}
                showSubtitles={jobTypesOptions[
                  job.type.typeName || "subtitles"
                ].editorMode.includes("subtitles")}
                mode={mode}
                toast={setAlert}
                useModal={useModal}
                jobActions={[
                  {
                    key: "export",
                    label: t("export"),
                    icon: faFileExport,
                    fullButton:
                      job.autoTranscriptDelivery ===
                      JobAutoTranscriptDeliveryOptions.delivered,
                    onClick: handleOpenExportModal,
                  },
                  {
                    key: "order",
                    label: t("order_transcription_button"),
                    icon: faTruckFast,
                    onClick: handleOrderFullTranscript,
                    fullButton: true,
                    hide:
                      job.autoTranscriptDelivery !==
                      JobAutoTranscriptDeliveryOptions.delivered,
                  },
                ]}
                featureFlags={{
                  experimentalSubtitlesEditor,
                  timecodePicker: timecodePickerFF,
                  useNewKeyboardShortcuts,
                  contentEditable: contentEditableFF,
                }}
                userSettings={user?.settings}
                updateUserSettings={updateUserSettings}
                startAsReadOnly={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Job;
