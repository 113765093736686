import axios from "axios";
import { UserRole } from "@sumit-platforms/types";

const UserInvitationService = ({ config }: { config: any }) => {
  const userInvitationEndpoint = `${config.server.host}/${config.server.userInvitations}`;

  const inviteNewUser = async (email: string, role: UserRole) => {
    try {
      await axios.post(`${userInvitationEndpoint}`, {
        email,
        role,
      });
    } catch (err: any) {
      throw err.response.data;
    }
  };

  const getInvitationByToken = async (token: string) => {
    try {
      const response = await axios.get(
        `${userInvitationEndpoint}/getInvitationByToken?token=${token}`
      );
      return response.data;
    } catch (err: any) {
      throw err.response.data;
    }
  };

  return {
    getInvitationByToken,
    inviteNewUser,
  };
};

export default UserInvitationService;
