import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { UserInvitationService } from "../../services";
import { UserInvitation } from "@sumit-platforms/types";

export const useUserInvitation = ({ config }: { config: any }) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [invitationDetails, setInvitationDetails] = useState<UserInvitation>();
  const token = searchParams.get("token");

  const userInvitationService = useMemo(
    () => UserInvitationService({ config }),
    []
  );

  useEffect(() => {
    const getInvitationData = async () => {
      if (token) {
        setLoading(true);
        const _invitationDetails =
          await userInvitationService.getInvitationByToken(token);
        setLoading(false);
        setInvitationDetails(_invitationDetails);
      }
    };

    getInvitationData();
  }, [token]);

  return {
    invitationDetails,
    loading,
    token,
  };
};
