import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ReactMediaRecorderHookProps,
  StatusMessages,
  useReactMediaRecorder,
} from "react-media-recorder";
import { useStopwatch } from "react-timer-hook";
import { recordStore } from "../../store";
import _ from "lodash";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Client,
  DEFAULT_UPLOAD_SETTINGS,
  MediaProvider,
  RecordsIDBModel,
  Toast,
  Upload,
} from "@sumit-platforms/types";
import { useNewUploads } from "./useNewUploads";
import { useAuth } from "./useAuth";
import { StoreApi, UseBoundStore } from "zustand";
import { useShallow } from "zustand/react/shallow";
import JobService from "../services/jobService";
import { useToast } from "./useToast";
import { generateId, loadMedia } from "../../utils";
import { indexDB } from "../../services";

const fileExtension = "wav";
const fileMimeType = "audio/wav";

const MEDIA_ACQUIRING_TIMEOUT = 5000;
const SAVE_RECORD_SNAPSHOT_INTERVAL = 10000;

export const useRecord = (
  props: ReactMediaRecorderHookProps & {
    config: any;
    clientStore: UseBoundStore<StoreApi<{ client: Client }>>;
    setToast: (toast: Toast | null) => void;
    openUploadRecordModal: (rec?: RecordsIDBModel | null) => void;
    openDeleteRecordModal: () => void;
  }
) => {
  const {
    status,
    error,
    startRecording,
    pauseRecording,
    resumeRecording,
    stopRecording,
    mediaBlobUrl, // relevant only in stop
    clearBlobUrl,
    previewAudioStream, // stream
  } = useReactMediaRecorder({
    audio: true,
    blobPropertyBag: {
      type: fileMimeType,
    },
  });

  const {
    isRecording,
    setIsRecording,
    setIsPaused,
    setMediaBlobUrl,
    isPaused,
    uploadSettings,
    setUploadSettings,
    updateUploadSettings,
  } = recordStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
      setIsRecording: state.setIsRecording,
      setIsPaused: state.setIsPaused,
      setMediaBlobUrl: state.setMediaBlobUrl,
      isPaused: state.isPaused,
      uploadSettings: state.uploadSettings,
      setUploadSettings: state.setUploadSettings,
      updateUploadSettings: state.updateUploadSettings,
    }))
  );
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mediaDuration, setMediaDuration] = useState<number | null>(null);
  const [isAcquiringMediaTimeout, setIsAcquiringMediaTimeout] = useState(false);
  const timer = useStopwatch({});
  const { user } = useAuth({ config: props.config });
  const { client } = props.clientStore();
  const { toastSuccess, toastInfo, toastError } = useToast({
    setToast: props.setToast,
  });
  const { handleCreateUpload } = useNewUploads({
    config: props.config,
    user,
    client,
  });
  const currentRecordName = useRef<string>();
  const statusRef = useRef<StatusMessages | null>(null);
  const durationRef = useRef<number>(0);
  const acquireMediaTimeout = useRef<NodeJS.Timeout | null>(null);
  const saveRecordInterval = useRef<NodeJS.Timeout | null>(null);
  const mediaRecorder = useRef<MediaRecorder>();
  const streamRecordStarted = useRef<boolean>(false);
  const recordChunksRef = useRef<Blob[]>([]);
  const submitRequest = useRef<boolean>(false);

  const jobService = useMemo(
    () => JobService({ config: props.config }),
    [props.config]
  );
  const { onAir, isAcquiringMedia } = useMemo(() => {
    return {
      onAir: status === "recording",
      isAcquiringMedia: status === "acquiring_media",
    };
  }, [status]);

  const isPermissionDenied = useMemo(
    () => error === "permission_denied" || isAcquiringMediaTimeout,
    [error, isAcquiringMediaTimeout]
  );

  let recordId = useMemo(() => generateId(), []);

  const recordStreamCleanup = useCallback(() => {
    if (
      mediaRecorder.current &&
      status !== "recording" &&
      status !== "paused"
    ) {
      if (mediaRecorder.current.state !== "inactive") {
        mediaRecorder.current.stop();
      }
      streamRecordStarted.current = false;
    }
  }, [status]);

  const getDefaultUploadSettings = useCallback(async () => {
    let uploadsSettings = DEFAULT_UPLOAD_SETTINGS;
    if (client?.uploadSettings) {
      // init the state with the client defaults
      uploadsSettings = _.merge(uploadsSettings, client.uploadSettings);
    }
    setUploadSettings(uploadsSettings);
  }, [client?.uploadSettings, setUploadSettings]);

  const upsertRecordToIDB = useCallback(
    async (file: File, duration: number) => {
      const recordRow: Partial<RecordsIDBModel> = {
        id: recordId,
        name:
          (file.name || "").trim() || (uploadSettings.name as string).trim(),
        lastModified: format(Date.now(), "dd/MM/yyyy, HH:mm"),
        file,
        duration,
      };
      try {
        await indexDB.upsert("records", recordRow);
      } catch (e) {
        console.log("Error while trying to upsert record file to IDB :", e);
      }
    },
    [recordId, uploadSettings, indexDB.records]
  );

  const storeRecordSnapshot = useCallback(async () => {
    const isRecording = statusRef.current === "recording";
    const isPaused = statusRef.current === "paused";

    if (!isRecording || isPaused) return;

    const blob = new Blob(recordChunksRef.current, { type: fileMimeType });
    const file = new File(
      [blob],
      uploadSettings.name || `recorded_audio.${fileExtension}`,
      { type: fileMimeType }
    );
    upsertRecordToIDB(file, durationRef.current);
  }, [
    status,
    previewAudioStream,
    isRecording,
    isPaused,
    fileMimeType,
    uploadSettings,
    fileExtension,
    timer,
  ]);

  const startRecordSnapshotInterval = useCallback(() => {
    saveRecordInterval.current = setInterval(
      storeRecordSnapshot,
      SAVE_RECORD_SNAPSHOT_INTERVAL
    );
  }, [storeRecordSnapshot, status]);

  const clearRecordSnapshotInterval = useCallback(() => {
    if (saveRecordInterval.current) {
      clearInterval(saveRecordInterval.current);
    }
  }, []);

  useEffect(() => {
    statusRef.current = status;
    durationRef.current = timer.totalSeconds;

    // Creating interval to fill the recordChunksRef
    const isPaused = status === "paused";
    const isRecording = status === "recording";

    if (isPaused && mediaRecorder.current?.state === "recording") {
      mediaRecorder.current.pause();
    } else if (isRecording && mediaRecorder.current?.state === "paused") {
      mediaRecorder.current.resume();
    }

    if (previewAudioStream && isRecording && !streamRecordStarted.current) {
      recordChunksRef.current = [];

      try {
        mediaRecorder.current = new MediaRecorder(previewAudioStream, {
          mimeType: "audio/webm", // check if wav is possible
        });

        if (mediaRecorder.current) {
          mediaRecorder.current.ondataavailable = (event: any) => {
            if (event.data && event.data.size > 0) {
              recordChunksRef.current.push(event.data);
            }
          };

          mediaRecorder.current.start(100); // interval of 100ms to capture record
        }
        streamRecordStarted.current = true;
      } catch (error) {
        console.error("Error initializing MediaRecorder:", error);
      }
    }

    return recordStreamCleanup;
  }, [previewAudioStream, status]);

  useEffect(() => {
    if (!client?.idClient) return;
    getDefaultUploadSettings();
  }, [client?.idClient]);

  useEffect(() => {
    // updating media blob url state here because it takes few seconds from the moment the user clicks "end & save"
    setMediaBlobUrl(mediaBlobUrl);
    readBlobMedia(mediaBlobUrl);
    if (submitRequest.current && mediaBlobUrl) {
      submitRecord(mediaBlobUrl);
      submitRequest.current = false;
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    // Trigger a timer to see if acquiring media is not possible

    if (isAcquiringMedia) {
      acquireMediaTimeout.current = setTimeout(() => {
        setIsAcquiringMediaTimeout(true);
      }, MEDIA_ACQUIRING_TIMEOUT);
    }

    return () => {
      if (acquireMediaTimeout.current) {
        clearTimeout(acquireMediaTimeout.current);
      }
    };
  }, [isAcquiringMedia]);

  const handleStart = useCallback(() => {
    if (!uploadSettings.name) {
      // Fix naming bug
      const recordName = `${t("unnamed_record")} - ${format(
        Date.now(),
        "dd/MM/yyyy, HH:mm"
      )}`;
      currentRecordName.current = recordName;

      updateUploadSettings({
        data: recordName,
        field: "name",
      });
    }
    startRecordSnapshotInterval();
    timer.start();
    if (isRecording) {
      resumeRecording();
    } else {
      startRecording();
      setIsRecording(true);
    }
    setIsPaused(false);
  }, [
    isRecording,
    resumeRecording,
    uploadSettings.name,
    setUploadSettings,
    timer,
    startRecording,
    setIsRecording,
    setIsPaused,
    startRecordSnapshotInterval,
    status,
  ]);

  const handlePause = useCallback(() => {
    timer.pause();
    pauseRecording();
    setIsPaused(true);
    storeRecordSnapshot();
  }, [timer, pauseRecording, storeRecordSnapshot, setIsPaused]);

  const handleRecordToggle = useCallback(() => {
    if (onAir) {
      handlePause();
    } else {
      handleStart();
    }
  }, [onAir, handlePause, handleStart]);

  const handleClearRecord = useCallback(() => {
    clearBlobUrl();
    setMediaDuration(null);
    updateUploadSettings({ field: "name", data: "" });
    setIsRecording(false);
    setIsPaused(false);
    timer.reset(new Date(), false);
    recordId = generateId();
    submitRequest.current = false;
  }, [timer, setIsRecording, updateUploadSettings, clearBlobUrl]);

  const removeRecordFromDB = useCallback(async (recordId: string) => {
    try {
      console.log(`Deleting record from IDB ${recordId}`);
      await indexDB.records.delete(recordId);
    } catch (e) {
      console.log(`Fail to delete record from IDB ${recordId}`);
    }
  }, []);

  const handleDeleteRecord = useCallback(async () => {
    await removeRecordFromDB(recordId);
    handleClearRecord();
    clearRecordSnapshotInterval();
  }, [handleClearRecord, clearRecordSnapshotInterval, removeRecordFromDB]);

  const readBlobMedia = useCallback(
    async (mediaBlobUrl?: string) => {
      if (!mediaBlobUrl) return;
      const file = await createFileFromMediaBlobUrl(mediaBlobUrl);
      if (file) {
        const fileData = await loadMedia(file);
        if (fileData?.duration) {
          setMediaDuration(fileData?.duration);
        }
      }
    },
    [mediaBlobUrl, setMediaDuration]
  );

  const prepareSubmitCurrentRecord = useCallback(() => {
    setIsLoading(true);
    stopRecording();
    clearRecordSnapshotInterval();
    timer.reset(new Date(), false);
    submitRequest.current = true;
    // the useEffect that listen to mediaBlobUrl will set isLoading to false.
  }, [timer]);

  const getFileFromIDB = useCallback((record: RecordsIDBModel) => {
    const file = record.file;

    // Download the file for testing
    const blob = new Blob([file], { type: "audio/webm" });
    const url = URL.createObjectURL(blob);
    return url;
  }, []);

  const createFileFromMediaBlobUrl = useCallback(
    async (mediaBlobUrl: string) => {
      try {
        const fileResponse = await fetch(mediaBlobUrl);
        const blob = await fileResponse.blob();

        const file = new File(
          [blob],
          `${uploadSettings.name}.${fileExtension}` ||
            `Record ${format(new Date(), "dd-MM-yyyy")}.${fileExtension}`,
          {
            type: blob.type,
            lastModified: new Date().getTime(),
          }
        );
        return file;
      } catch (e) {
        console.error(`Failed to create media blob: ${e}`);
        handleUploadRecordFail();
        return null;
      }
    },
    [uploadSettings]
  );

  const handleUploadRecordSuccess = useCallback(
    async (upload: Upload, IDBRecord?: RecordsIDBModel | null) => {
      toastInfo(t("create_job_from_record"));

      await jobService.newCreate({
        uploadIds: [upload.idUpload],
        uploadForm: uploadSettings,
      });

      toastSuccess(t("submit_record_success"));
      handleClearRecord();

      if (IDBRecord) {
        await removeRecordFromDB(IDBRecord.id);
      } else {
        await removeRecordFromDB(recordId);
      }
      setIsLoading(false);
    },
    [jobService, removeRecordFromDB, handleClearRecord, toastSuccess, t]
  );

  const submitRecord = useCallback(
    async (mediaBlob: string, IDBRec?: RecordsIDBModel | null) => {
      try {
        if (!mediaBlob) {
          toastError(t("submit_record_failure"));
          return false;
        }

        const file = await createFileFromMediaBlobUrl(mediaBlob);

        if (!file) {
          handleUploadRecordFail();
        }

        await handleCreateUpload({
          files: [file as File],
          onFail: handleUploadRecordFail,
          client: client as Client,
          mediaProvider: MediaProvider.mustRecorder,
          duration: timer.totalSeconds,
          onSuccess: (upload: Upload) =>
            handleUploadRecordSuccess(upload, IDBRec),
        });
        return true;
      } catch (error) {
        toastError(t("submit_record_fail"));
        setIsLoading(false);
        return false;
      }
    },
    [
      handleUploadRecordSuccess,
      handleClearRecord,
      mediaBlobUrl,
      uploadSettings,
      handleCreateUpload,
      client,
      timer.totalSeconds,
      jobService,
      handleClearRecord,
      toastError,
      createFileFromMediaBlobUrl,
      t,
    ]
  );

  const submitRecordFromIDB = useCallback(
    (rec: RecordsIDBModel) => {
      const url = getFileFromIDB(rec);
      if (!url) {
        handleUploadRecordFail();
        return;
      }
      submitRecord(url, rec);
    },
    [updateUploadSettings, submitRecord]
  );

  const submitCurrentRecord = useCallback(() => {
    if (uploadSettings?.name !== currentRecordName.current) {
      updateUploadSettings({ field: "name", data: uploadSettings?.name });
    }
    prepareSubmitCurrentRecord();
  }, [prepareSubmitCurrentRecord, updateUploadSettings]);

  const handleSubmitUpload = useCallback(
    async (rec?: RecordsIDBModel | null) => {
      if (rec) {
        submitRecordFromIDB(rec);
      } else {
        if (status === "idle") {
          console.error(
            "cannot find activeIDBRecord. probably closure problem"
          );
          handleUploadRecordFail();
          return;
        }
        submitCurrentRecord();
      }
    },
    [submitCurrentRecord, status]
  );

  const handleSaveRecord = useCallback(() => {
    if (!isPaused) {
      handlePause();
    }
    clearRecordSnapshotInterval();
    props.openUploadRecordModal();
  }, [
    timer,
    isPaused,
    handlePause,
    stopRecording,
    mediaBlobUrl,
    props.openUploadRecordModal,
  ]);

  const handleUploadRecordFail = useCallback(() => {
    toastError(t("upload_failed"));
    setIsLoading(false);
  }, [toastError, t]);

  const onSaveIDBRecordClick = useCallback(
    (record: RecordsIDBModel | null) => {
      if (!record) return;
      updateUploadSettings({ field: "name", data: record.name });
      props.openUploadRecordModal(record);
    },
    [updateUploadSettings, props.openUploadRecordModal]
  );

  const openDeleteModal = useCallback(() => {
    props.openDeleteRecordModal();
  }, [props.openDeleteRecordModal]);

  const onUploadModalClose = useCallback(() => {
    updateUploadSettings({ field: "name", data: currentRecordName.current });
  }, [updateUploadSettings]);

  return {
    onAir,
    isAcquiringMedia,
    isPermissionDenied,
    handleStart,
    handlePause,
    handleRecordToggle,
    handleDeleteRecord,
    handleSaveRecord,
    status,
    error,
    startRecording,
    pauseRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    timer,
    submitRecord,
    isLoading,
    mediaDuration,
    handleSubmitUpload,
    recordId,
    onSaveIDBRecordClick,
    openDeleteModal,
    onUploadModalClose,
  };
};
