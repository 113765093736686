import { redirect } from "react-router-dom";

export default {
  environment: "development",
  envUrl: "https://dev.must.sumit-ai.com",
  server: {
    host: "https://dev.must-server.sumit-ai.com",
    auth: "auth",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    files: "files",
    export: "export",
    invoices: "invoices",
  },
  googleAuthProvider: {
    apiKey: "AIzaSyB9fmJ3aM8i3V_beh_nnX3-WxWs-M15IEg",
    clientId:
      "360745002639-ghl8v1tkaumose81fbo6jhejo5g7e3sm.apps.googleusercontent.com",
  },
  microsoftAuthProvider: {
    clientId: "f1a496e3-b061-4b40-aa39-a7b06ef0ad72",
    scopes: ["Calendars.Read"],
    redirectUri: "https://dev.must.sumit-ai.com",
  },
  firebase: {
    clientId:
      "360745002639-podsug40kta99fop77a2mrtruoc83sge.apps.googleusercontent.com",
    apiKey: "AIzaSyB9fmJ3aM8i3V_beh_nnX3-WxWs-M15IEg",
    authDomain: "sumit-dev-0.firebaseapp.com",
    projectId: "sumit-dev-0",
    storageBucket: "sumit-dev-0.appspot.com",
    messagingSenderId: "360745002639",
    appId: "1:360745002639:web:51ada10804ecd6fe87f2fa",
    measurementId: "G-1EPFNZV0VQ",
    tenantId: "b2b-platform-jaug4",
  },
  featureFlags: {
    apiKey:
      "must:development.3b27bd709fdd62648d4db2fbd2814a6306f8d430226da646ccc80093",
    extract: {
      users: [1, 2, 3, 4, 5, 8],
      enabled: true,
    },
  },
  cloudinary: {
    cloud_name: "df9neab8j",
    upload_preset: "ejh79zy5",
    api_key: "724134266828553",
  },
};
