import React, { FC, useCallback, useState } from "react";
import { SignUpForm } from "@sumit-platforms/ui-bazar/v3";
import {
  useAuth,
  useMFA,
  useUserInvitation,
} from "@sumit-platforms/ui-bazar/hooks";
import config from "../../config";
import { MfaVerify } from "@sumit-platforms/ui-bazar";
import { useNavigate } from "react-router-dom";

import "./Auth.scss";

const SignUpPage: FC = (): JSX.Element => {
  const [stage, setStage] = useState<"signupForm" | "mfa">("signupForm");
  const { signOut, handleUserAuthentication } = useAuth({
    config,
  });
  const navigate = useNavigate();

  const handleUserEnrollSuccess = useCallback(() => {
    setStage("mfa");
  }, []);

  const handleUserEnrollFailed = useCallback(() => {
    console.error("user enroll failed");
  }, []);

  const handleUserAuthenticated = useCallback(async () => {
    await handleUserAuthentication();
    navigate("/");
  }, [navigate, handleUserAuthentication]);

  const { enrollSMS } = useMFA({
    config,
    onEnrollSuccess: () => {
      handleUserEnrollSuccess();
    },
    onEnrollFail: (error) => {
      handleUserEnrollFailed();
    },
  });

  const onUserSignupSuccess = useCallback(() => {
    enrollSMS();
  }, [enrollSMS]);

  const handleUserLogout = useCallback(async () => {
    try {
      await signOut({ redirectAfterLogout: false });
      setStage("signupForm");
    } catch (e) {
      console.error(e);
    }
  }, [signOut]);

  return (
    <div className="SignUpPage">
      {stage === "signupForm" && (
        <SignUpForm onSignupSuccess={onUserSignupSuccess} config={config} />
      )}
      {stage === "mfa" && (
        <MfaVerify
          config={config}
          type={"sms"}
          onFail={handleUserEnrollFailed}
          onSuccess={handleUserAuthenticated}
          logOut={handleUserLogout}
          showHeader
          showHeaderImage={false}
        />
      )}
    </div>
  );
};

export default SignUpPage;
